import * as React from "react";
import Redirect from "../../../components/redirect";
//--------------------------------------------------

  const url = "https://drive.google.com/drive/folders/1np2MA_aaSxjP0FieLv0yUvQbQtN9N3eI?usp=sharing";

//--------------------------------------------------
// common config below this line, no need to change
const IndexPage = () => ( <Redirect goto_url={url} /> );
export default IndexPage;
